



















import { Component } from 'vue-property-decorator';
import PromisePool from '@supercharge/promise-pool';
import SeqSendBase from './SeqSendBase.vue';
import { IUserProfile, IUserService } from '@/interfaces';
import { ISeqSend, ISeqSendStep, ISeqSendCreate, ISeqSendStepCreate } from '@/interfaces/seq_sends';
import { dispatchCreateSeqSend, dispatchCreateSeqSendStep } from '@/store/main/accessors/dispatch';
import Stepper from '@/components/seq_sends/Stepper.vue';

@Component({
  components: { Stepper },
})
export default class SeqSendsCreate extends SeqSendBase {
  public async mounted() {
    const user: IUserProfile | null = await this.getUserProfile();
    if (!!user) {
      this.fromColumn.services = user.services.map(
        (item: IUserService, idx: number): ISeqSendStep => {
          return {
            id: idx + 1,
            allowedSenders: item.allowed_senders,
            service: item.service,
            ttl: '00:05:00',
            sender: '',
          };
        },
      );
    }
  }

  public async createSeqSendSteps({ id }: ISeqSend) {
    const { results, errors } = await PromisePool.withConcurrency(10)
      .for(this.toColumn.services)
      .process(async (seqSendStep: ISeqSendStep) => {
        const { service, sender, ttl: time }: ISeqSendStep = seqSendStep;
        const ttl = this.convertDurationToSeconds(String(time));
        const seqSendStepCreate: ISeqSendStepCreate = {
          service,
          sender,
          ttl,
        };
        const data: { id: number; body: ISeqSendStepCreate } = { id, body: seqSendStepCreate };
        await dispatchCreateSeqSendStep(this.$store, data);
      });
  }

  public async done() {
    const seqSendCreate: ISeqSendCreate = {
      name: this.serviceName,
    };

    const seqSend: ISeqSend = await dispatchCreateSeqSend(this.$store, seqSendCreate);
    await this.createSeqSendSteps(seqSend);

    this.$router.push({ name: 'main-seq-sends-all' });
  }
}
